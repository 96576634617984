var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navtab" }, [
    _c(
      "div",
      { staticClass: "tabs" },
      _vm._l(_vm.tabs, function(tab, index) {
        return _c(
          "div",
          {
            class: { tab: true, active: _vm.current == index },
            on: {
              click: function($event) {
                return _vm.clickTab(tab, index)
              }
            }
          },
          [_vm._v(_vm._s(tab.name) + "\n        ")]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }