import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import config from '@/config'
import notify from '@/notify'
import  './assets/validate'
import 'normalize.css'
import * as extension from 'extensionizer';
const PortStream = require('extension-port-stream');
const Dnode = require('dnode/browser')

axios.defaults.baseURL = config.base_url;
axios.defaults.withCredentials = true;
//添加响应拦截器
axios.interceptors.response.use(function(response){
    //对响应数据做些事
    console.log(response);
    if(response.status==401){
        router.push({name:'login'})
    }
    return response
},function(error){
    throw error;
});

const extensionPort = extension.runtime.connect(config.extension_id)
const connectionStream = new PortStream(extensionPort)

const dnode = Dnode()
connectionStream.pipe(dnode).pipe(connectionStream)
dnode.on('remote', function(manager) {
    console.log(manager)
    Vue.prototype.$manager = manager
})


Vue.prototype.$http = axios
router.afterEach(route => {
    window.document.title = route.meta.title+' - 原本 - 免费的原创保护和交易平台';
})

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
