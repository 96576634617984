<template>
    <div class="navtab">
        <div class="tabs">
            <div @click="clickTab(tab,index)" :class="{'tab':true,'active':current==index}"
                 v-for="(tab,index) in tabs">{{ tab.name }}
            </div>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
    export default {
        name: 'navtab',
        props:['tabs'],
        data () {
            return {
                current:0
            }
        },

        methods: {
            clickTab(tab,index) {
                this.current=index
                console.log(this.tabs);
                if (typeof(tab.click) == 'function') {
                    tab.click();
                }
            }
        },
        created(){
            console.log(this.tabs);
        }

    }
</script>

<style lang="scss" scoped>
    @import '../common/common';
    .navtab {
        position: relative;
        height: 50px;
        border-bottom: 1px solid $line-color-lt;

        .tabs {
            position: relative;
            height: 40px;
            .tab {
                position: relative;
                width: auto;
                margin-top: 10px;
                height: 40px;
                line-height: 40px;
                float: left;
                font-size: $text-size-sm;
                color: $text-color-dk;
                cursor: pointer;
                text-align: center;
                padding: 0 10px;
                &:hover {
                    color: $text-color-xd;
                }

                &.active,
                &.active:hover {
                    color: $text-color-xd;
                    border-bottom: 2px solid $text-color-xd;
                }
            }
        }

    }

</style>
