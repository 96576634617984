var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "verify" }, [
    _c("div", { staticClass: "summary" }, [
      _vm._v("\n        已将验证信息发送到您的注册邮箱"),
      _c("br"),
      _vm._v("\n        " + _vm._s(_vm.email) + "请前往此邮箱完成验证\n    ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form" }, [
      _c(
        "button",
        {
          staticClass: "sub-btn verify-btn",
          class: { disabled: _vm.isloading },
          attrs: { disabled: _vm.isloading },
          on: { click: _vm.checkVerify }
        },
        [_vm._v(_vm._s(_vm.subbtntext))]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isValid,
              expression: "!isValid"
            }
          ],
          staticClass: "un-verify-tip"
        },
        [_vm._v("您尚未完成注册，请前往邮箱点击验证通过！")]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "tip" }, [
      _c("div", [_vm._v("没有收到邮件？")]),
      _vm._v(" "),
      _c("div", [
        _c("ul", [
          _c("li", [_vm._v("请检查您邮箱的订阅邮件和垃圾邮件分组")]),
          _vm._v(" "),
          _c("li", [
            _vm._v("如果仍未收到，请尝试"),
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    return _vm.resend()
                  }
                }
              },
              [_vm._v("重新发送确认邮件")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isResend === 1,
              expression: "isResend===1"
            }
          ],
          staticClass: "un-verify-tip"
        },
        [_vm._v("邮件发送成功")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isResend === 2,
              expression: "isResend===2"
            }
          ],
          staticClass: "un-verify-tip"
        },
        [_vm._v("邮件发送失败，请在1分钟后再次尝试")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }