<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  data () {
    return {

    }
  },

  methods: {

  }
}
</script>

<style lang="scss">
*{box-sizing: border-box}
body{
  color: #333;
  font-size: 16px;
  font-family: 'PingFang SC', 'HanHei SC', 'Microsoft YaHei', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
}
#app{
  width: 740px;
  height: 480px;
  overflow: hidden;
  margin: 0 auto;
}
a{text-decoration:none;outline:none;color: #333;}
a:active{text-decoration:none;color: #333}
a:hover{text-decoration:none;color: #333}
a:visited{text-decoration:none;color: #333}
a:link{text-decoration:none;color: #333}
.form{
  width: 280px;
}
.form-group{
  margin-bottom: 10px;
}
input,button{
  outline: none;
}
.input-role{
  width: 100%;
  display: block;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius:4px;
  padding:0 10px ;
  outline: none;
}
.input-role:focus{
  border-color:#32d182 ;
}
.input-role::placeholder{
  color: #ccc;
}
.disabled{
  background: #ccc!important;
  cursor: not-allowed!important;
  border-color:#ccc!important ;
}
.sub-btn{
  width: 100%;
  display: block;
  background: #32d182;
  border: 1px solid #32d182;
  line-height: 38px;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  outline: none;
  text-align: center;
}
.messages-tips {
  font-size: 12px;
  line-height: 1;
  color: red;
  padding-top: 5px;
}
.clearfix {
  zoom:1;
  &:after {clear:both;content:'.';display:block;width: 0;height: 0;visibility:hidden;}
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
@font-face {
  font-family: 'ybicon';
  src:  url('./common/ybicons/fonts/ybicon.eot?xf7tp5');
  src:  url('./common/ybicons/fonts/ybicon.eot?xf7tp5#iefix') format('embedded-opentype'),
  url('./common/ybicons/fonts/ybicon.ttf?xf7tp5') format('truetype'),
  url('./common/ybicons/fonts/ybicon.woff?xf7tp5') format('woff'),
  url('./common/ybicons/fonts/ybicon.svg?xf7tp5#ybicon') format('svg');
  font-weight: normal;
  font-style: normal;
}
</style>
