<template>
    <div class="pager">
        <div class="page-total">共<span class="page-count">{{total}}</span>页</div>
        <div class="fr">
            <div :class="{'item':true, 'nav':true, 'disabled': current == 1}" @click="prev()"><i
                    class="ybicon yb-last"></i></div>
            <div :class="{'item':true, 'active':page==current}" @click="loadPage(page)"
                 v-for="page in pageToShow">{{ page }}
            </div>
            <div :class="{'item':true, 'nav':true, 'disabled': current == total}" @click="next()"><i
                    class="ybicon yb-next"></i></div>
            <form @submit.prevent="loadPage(gotoPage)" class="goto">
                <div class="input-box">第<input type="number" v-model="gotoPage">页</div>
                <button class="goto-btn" :disabled="gotoPage>total || gotoPage<0"  type="submit">确定</button>
            </form>
        </div>

    </div>
</template>

<script type="text/ecmascript-6">
    export default {
        name: 'pager',
        props:['total','page'],
        data () {
            return {
                gotoPage:'',
                current:this.page,
                pageToShow:''
            }
        },
        watch:{
            total:function(newValue){
                this.total=newValue;
                this.update();
            },
            page:function(newValue){
                this.current=newValue;
                this.update();
            }
        },
        methods: {
            prev() {
                if (this.current == 1)
                    return;
                this.loadPage(this.current - 1);
            },
            next() {
                if (this.current == this.total)
                    return;
                this.loadPage(this.current + 1);
            },
            loadPage(page) {
                if(page==='...')return;
                this.current = page;
                if(this.current<1){
                    this.current=1
                }
                this.$emit('loadpage',this.current)
                this.update();
            },
            update() {

                this.pageToShow = [];
                if (this.current <= 2) {
                    for (var i = 1; i <= this.total && i <= 3; i++) {
                        this.pageToShow.push(i);
                    }
                    this.total>3 && (this.pageToShow=this.pageToShow.concat(['...',this.total]))
                } else if (this.current >= this.total - 1) {
                    for (var i = this.total, c = 0; c < 3 && i > 0; i--, c++) {
                        this.pageToShow.unshift(i);
                    }
                    this.total>3 && (this.pageToShow=[1,'...'].concat(this.pageToShow))
                } else {
                    this.pageToShow = [
                        this.current - 1,
                        this.current,
                        +this.current + 1
                    ];
                    this.total>3 && (this.pageToShow=[1,'...'].concat(this.pageToShow).concat(['...',this.total]))
                }



            }
        },
        created(){
            this.update();
            //console.log(this.gotoPage,this.total);
        }

    }
</script>

<style lang="scss" scoped>
    @import '../common/common';
    $pager-height:26px;
    .pager {
        position: relative;
        margin-top: 18px;
        height: $pager-height;
        text-align: center;
        font-size: 0;
        color: $text-color-xd;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &>div{
            display: inline-block;
            font-size: $text-size-xs;
        }
        .page-total{
            font-size: $text-size-xs;
            color: $text-color-md;
            float: left;
            line-height: $pager-height;
        }
        .fr{
            float: right;
            font-size: 0;
        }
        .item {
            position: relative;
            display: inline-block;
            min-width: $pager-height;
            padding: 0 5px;
            height: $pager-height;
            line-height: $pager-height;
            text-align: center;
            cursor: pointer;
            border-radius: 2px;
            margin-left:10px;
            font-size: $text-size-xs;
            border: 1px solid $line-color-md;
            i {
                line-height: $pager-height;
                font-size: 12px;
            }

            &.nav {
                color: $text-color-md;
            }

            &:hover,
            &.active {
                color: #ffffff;
                background-color: $highlight-bg-color;
                border-color: $highlight-line-color;
            }

            &.disabled,
            &.disabled:hover {
                cursor: not-allowed;
                color: $text-color-lt;
                background-color: $light-bg-color!important;
                border-color: $line-color-md;
            }
        }
        .goto{
            display: inline-block;
            margin-left: 16px;
            font-size: 0;
            .input-box{
                display: inline-block;
                color:$text-color-md;
                font-size: $text-size-xs;
                input{
                    width: $pager-height;
                    height: $pager-height;
                    line-height: $pager-height;
                    text-align: center;
                    border-radius: 2px;
                    border: 1px solid $line-color-md;
                    margin: 0 2px;
                    &:focus{
                        border-color:$yuanben-green ;
                    }
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                    }
                    &[type="number"]{
                        -moz-appearance: textfield;
                    }
                }
            }
            .goto-btn{
                float: right;
                width: 42px;
                height: $pager-height;
                cursor: pointer;
                line-height: 22px;
                text-align: center;
                border-radius: 2px;
                margin: 0 0 0 10px;
                border: 1px solid $line-color-md;
                background: none;
                color: $text-color-md;
                font-size: $text-size-xs;
                &:hover {
                    color: $yuanben-green;
                    border-color: $yuanben-green;
                }
                &:disabled,
                &:disabled:hover {
                    cursor: not-allowed;
                    color: $text-color-lt;
                    background-color: $light-bg-color;
                }
            }
        }
    }
</style>
