<template>
    <div class="nav">
        <router-link class="nav-item" to="/face/login">登录</router-link>
        <router-link class="nav-item" to="/face/register">注册</router-link>
    </div>
</template>

<script type="text/ecmascript-6">
    export default {
        name: '',
        data () {
            return {}
        },

        methods: {}
    }
</script>

<style scoped>
.nav{
    font-size: 22px;
    margin-bottom: 60px;
}
.nav-item{
    text-align: center;
    color: #ccc;
    padding: 0 30px;
    line-height: 1;
    border-left:1px solid #ccc ;
}
.nav-item:nth-child(1){
    border-left: none;
}
.router-link-active{
    color: #333;
}
</style>
