/**
 * Created by Yuanben on 2017/7/13.
 */
import Vue from 'vue'
import Router from 'vue-router'
import face from '@/pages/face.vue'
import login from '@/pages/login.vue'
import register from '@/pages/register.vue'
import verify from '@/pages/verify.vue'
import certification from '@/pages/certification.vue'
import imgpicker from '@/pages/imgpicker.vue'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path:'/',
            redirect: '/face/login',
            meta:{title:'登录'}
        },
        {
            path: '/face',
            name: 'face',
            component: face,
            children: [
                {
                    path: 'login',
                    name: 'login',
                    component: login,
                    meta:{title:'登录'}
                },
                {
                    path: 'register',
                    name: 'register',
                    component: register,
                    meta:{title:'注册'}
                }
            ]
        },
        {
            path: '/verify',
            name: 'verify',
            component: verify,
            meta:{title:'验证邮箱'}
        },
        {
            path: '/certification',
            name: 'certification',
            component: certification,
            meta:{title:'实名认证'}
        },
        {
            path: '/imgpicker',
            name: 'imgpicker',
            component: imgpicker,
            meta:{title:'选择图片'}
        }
    ]
})