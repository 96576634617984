var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "certification" }, [
    _c("div", { staticClass: "certification-user" }, [
      _c("img", {
        staticClass: "certification-user-img",
        attrs: { src: _vm.user.avatar, alt: "" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "certification-user-name" }, [
        _vm._v(_vm._s(_vm.user.pseudonym))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "loginout", on: { click: _vm.loginout } }, [
        _vm._v("切换账号")
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "certification-form form" }, [
      _c("div", { staticClass: "certification-form-title" }, [
        _vm._v("完成实名认证成为原创者")
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.realname,
                  expression: "model.realname"
                }
              ],
              staticClass: "input-role",
              attrs: {
                name: "realname",
                type: "text",
                placeholder: "您的真实姓名"
              },
              domProps: { value: _vm.model.realname },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.model, "realname", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("realname"),
                    expression: "errors.has('realname')"
                  }
                ],
                staticClass: "messages-tips"
              },
              [_vm._v(_vm._s(_vm.errors.first("realname")))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "messages-tips" }, [
              _vm._v(_vm._s(_vm.realname.errortips))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: {
                    rules: {
                      required: true,
                      regex: /(^\d{15}$)|(^\d{17}([0-9]|X)$)/
                    }
                  },
                  expression:
                    "{rules:{required:true,regex:/(^\\d{15}$)|(^\\d{17}([0-9]|X)$)/}}"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.id_card,
                  expression: "model.id_card"
                }
              ],
              staticClass: "input-role",
              attrs: {
                name: "id_card",
                type: "text",
                placeholder: "您的身份证号码"
              },
              domProps: { value: _vm.model.id_card },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.model, "id_card", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("id_card"),
                    expression: "errors.has('id_card')"
                  }
                ],
                staticClass: "messages-tips"
              },
              [_vm._v(_vm._s(_vm.errors.first("id_card")))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "messages-tips" }, [
              _vm._v(_vm._s(_vm.id_card.errortips))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.captcha,
                  expression: "model.captcha"
                }
              ],
              staticClass: "input-role captcha-input",
              attrs: {
                autocomplete: "off",
                name: "captcha",
                type: "text",
                placeholder: "验证码"
              },
              domProps: { value: _vm.model.captcha },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.model, "captcha", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "captcha-img",
              attrs: { src: _vm.captchaSrc },
              on: { click: _vm.changeCaptcha }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("captcha"),
                    expression: "errors.has('captcha')"
                  }
                ],
                staticClass: "messages-tips"
              },
              [_vm._v(_vm._s(_vm.errors.first("captcha")))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "messages-tips" }, [
              _vm._v(_vm._s(_vm.captcha.errortips))
            ])
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              class: { "sub-btn": true, disabled: _vm.isloading },
              attrs: { type: "submit", disabled: _vm.isloading }
            },
            [_vm._v(_vm._s(_vm.subbtntext))]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "certification-form-sup" }, [
      _c("span", [_vm._v("实名认证")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }