<template>
    <div class="register">
        <form @submit.prevent="onSubmit" class="form">
            <div class="form-group">
                <div class="form-group">
                    <input v-validate="'required|email'" class="input-role" v-model="model.email" name="email" type="email"  placeholder="输入注册邮箱"/>
                    <div v-show="errors.has('email')" class="messages-tips">{{ errors.first('email') }}</div>
                    <div class="messages-tips">{{email.errortips}}</div>
                </div>
            </div>
            <div class="form-group">
                <div class="form-group">
                    <input v-validate="'required'" class="input-role" v-model="model.pseudonym" name="pseudonym" type="text"  placeholder="输入用户笔名"/>
                    <div v-show="errors.has('pseudonym')" class="messages-tips">{{ errors.first('pseudonym') }}</div>
                    <div class="messages-tips">{{pseudonym.errortips}}</div>
                </div>
            </div>
            <div class="form-group">
                <div class="form-group">
                    <input v-validate="'required|min:6'" class="input-role" v-model="model.password" name="password" type="text"  placeholder="输入密码"/>
                    <div v-show="errors.has('password')" class="messages-tips">{{ errors.first('password') }}</div>
                    <div class="messages-tips">{{password.errortips}}</div>
                </div>
            </div>
            <button type="submit" :disabled="isloading" :class="{'sub-btn':true,'disabled':isloading}">{{subbtntext}}</button>
        </form>
    </div>
</template>

<script type="text/ecmascript-6">
    export default {
        name: '',
        data () {
            return {
                model: {
                    password: '',
                    email: '',
                    pseudonym:''
                },
                subbtntext:'注册',
                isloading:false,
                password:{
                    errortips:''
                },
                email:{
                    errortips:''
                },
                pseudonym:{
                    errortips:''
                }
            }
        },
        methods: {
            showErrTip(errData){
                for(let key in errData){
                    this[key].errortips=errData[key].join()
                }
            },
            onSubmit () {
                this.$validator.validateAll().then(res=>{
                    if(!res)return;
                    let data={
                        email:this.model.email,
                        password:this.model.password,
                        pseudonym:this.model.pseudonym
                    }
                    this.isloading=true
                    this.subbtntext='提交中'
                    this.$http.post('/register',data,{withCredentials: true})
                        .then( (res) => {
                            this.isloading=false
                            this.subbtntext='注册'
                            this.$router.push({name:'verify',query:{email:data.email}})
                        })
                        .catch( (err) => {
                            this.isloading=false
                            this.subbtntext='注册'
                            this.showErrTip(err.response.data)
                        })
                })


            }
        }
    }
</script>

<style scoped>

</style>
