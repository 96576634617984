<template>
    <div class="login">
        <form @submit.prevent="onSubmit" class="form">
            <div class="form-group">
                <input v-validate="'required|email'" class="input-role" v-model="model.email" name="email" type="email"
                       placeholder="输入登录邮箱"/>
                <div v-show="errors.has('email')" class="messages-tips">{{ errors.first('email') }}</div>
                <div class="messages-tips">{{email.errortips}}</div>
            </div>
            <div class="form-group">
                <input v-validate="'required|min:6'" class="input-role" v-model="model.password" name="password"
                       type="password" placeholder="输入密码"/>
                <div v-show="errors.has('password')" class="messages-tips">{{ errors.first('password') }}</div>
                <div class="messages-tips">{{password.errortips}}</div>
            </div>
            <div class="form-group">
                <input v-validate="'required'" autocomplete="off" class="input-role captcha-input" v-model="model.captcha" name="captcha"
                       type="text" placeholder="验证码"/>
                <img :src="captchaSrc" @click="changeCaptcha" class="captcha-img">
                <div v-show="errors.has('captcha')" class="messages-tips">{{ errors.first('captcha') }}</div>
                <div class="messages-tips">{{captcha.errortips}}</div>
            </div>
            <button type="submit" :disabled="isloading" :class="{'sub-btn':true,'disabled':isloading}">{{subbtntext}}
            </button>
        </form>
        <a href="https://yuanben.io/forget" target="_blank" class="out-link">忘记密码？</a>
    </div>
</template>

<script type="text/ecmascript-6">
    import config from '@/config'
    import notify from '@/notify'
    import CRC32 from '@/utils/crc32'

    export default {
        name: '',
        data() {
            return {
                model: {
                    password: '',
                    email: '',
                    captcha:''
                },
                subbtntext: '登录',
                isloading: false,
                password: {
                    errortips: ''
                },
                email: {
                    errortips: ''
                },
                captchaSrc:config.base_url+'/captcha',
                captcha:{
                    errortips:''
                }
            }
        },
        methods: {
            showErrTip(errData) {
                for (let key in errData) {
                    this[key].errortips = errData[key].join()
                }
            },
            changeCaptcha(){
                this.captchaSrc=config.base_url+'/captcha'+'?s=' + Math.random();
            },
            onSubmit() {
                const crc32 = new CRC32();
                this.$validator.validateAll().then(res => {
                    if (!res) return;
                    let data = {
                        email: this.model.email,
                        password: this.model.password,
                        captcha: this.model.captcha,
                        remember: 0,
                        'crc32': "" + crc32.crc32_str(this.model.email + this.model.password + 0)
                    }
                    this.isloading = true
                    this.subbtntext = '提交中'
                    this.$http.post('/login', data, {withCredentials: true})
                        .then((res) => {
                            console.log(res)
                            this.isloading = false
                            this.subbtntext = '登录'
                            if (!res.status) {
                                this.changeCaptcha();
                                //非200状态
                                if (res.response) {
                                    this.showErrTip(res.response.data)
                                }
                            } else {
                                if (!!res.data.name) {
                                    //已实名认证
                                    if (this.$route.query.cc0 == 1) {
                                        //当用户点击cc0按钮
                                        this.$manager.openImagePicker()
                                        window.close()
                                    } else if (this.$route.query.cc0 == 0) {
                                        this.$manager.login()
                                        // notify({method: 'loginSuccess'}, function () {
                                        //     console.log('notify login sucess ...')
                                        // })
                                        window.close()
                                    } else {
                                      window.close()
                                    }

                                } else if(res.data.email_verified !== 1) {
                                    //邮箱未激活
                                    this.$router.push({name:'verify',query:{email:res.data.email}})
                                } else {
                                    //登录却未实名认证
                                    this.$router.push({
                                        name: 'certification',
                                        query: {pseudonym: res.data.pseudonym, avatar: res.data.avatar, ...(this.$route.query.hasOwnProperty('cc0') ? {cc0: this.$route.query.cc0}:{})}
                                    })
                                }
                            }


                        })
                        .catch((err) => {
                            this.isloading = false
                            this.subbtntext = '登录';
                            this.changeCaptcha();
                            if (err.response) {
                                this.showErrTip(err.response.data)
                            }

//                            console.log(22,err)
                        })
                })


            }
        },
      created(){
        console.log(111)
        this.changeCaptcha();
        console.log(this.$route.query)
      }
    }
</script>

<style lang="scss" scoped>
    .login{
        .out-link {
            font-size: 12px;
            color: #00816a;
            line-height: 38px;
        }
        .captcha-input{
            width: 50%;
            display: inline-block;
        }
        .captcha-img{
            width:40%;
            cursor: pointer;
            vertical-align: bottom;
            float: right;
        }
    }


</style>
