/**
 * Created by zc on 2017/7/15.
 */
import Vue from 'vue'
import VeeValidate, {Validator} from 'vee-validate'
import zh_CN from 'vee-validate/dist/locale/zh_CN';//引入中文文件

// 配置中文
Validator.localize({zh_CN});

const config = {
    locale: 'zh_CN',
    dictionary:{
        zh_CN: {
            messages: {
                email: () => '邮箱格式错误',
                required: ( field )=> "请输入" + field
            },
            attributes:{
                realname:'真实姓名',
                id_card:'身份证号码',
                captcha:'验证码',
                email:'邮箱',
                password:'密码',
                pseudonym: '笔名',
                phone: '手机'
            }
        }
    }
};

Vue.use(VeeValidate,config);

Validator.extend('phone', {
    messages: {
        zh_CN:field => field + '格式错误',
    },
    validate: value => {
        return value.length == 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/.test(value)
    }
});