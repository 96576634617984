var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register" }, [
    _c(
      "form",
      {
        staticClass: "form",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email",
                  expression: "'required|email'"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.email,
                  expression: "model.email"
                }
              ],
              staticClass: "input-role",
              attrs: {
                name: "email",
                type: "email",
                placeholder: "输入注册邮箱"
              },
              domProps: { value: _vm.model.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.model, "email", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("email"),
                    expression: "errors.has('email')"
                  }
                ],
                staticClass: "messages-tips"
              },
              [_vm._v(_vm._s(_vm.errors.first("email")))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "messages-tips" }, [
              _vm._v(_vm._s(_vm.email.errortips))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.pseudonym,
                  expression: "model.pseudonym"
                }
              ],
              staticClass: "input-role",
              attrs: {
                name: "pseudonym",
                type: "text",
                placeholder: "输入用户笔名"
              },
              domProps: { value: _vm.model.pseudonym },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.model, "pseudonym", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("pseudonym"),
                    expression: "errors.has('pseudonym')"
                  }
                ],
                staticClass: "messages-tips"
              },
              [_vm._v(_vm._s(_vm.errors.first("pseudonym")))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "messages-tips" }, [
              _vm._v(_vm._s(_vm.pseudonym.errortips))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|min:6",
                  expression: "'required|min:6'"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.password,
                  expression: "model.password"
                }
              ],
              staticClass: "input-role",
              attrs: {
                name: "password",
                type: "text",
                placeholder: "输入密码"
              },
              domProps: { value: _vm.model.password },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.model, "password", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("password"),
                    expression: "errors.has('password')"
                  }
                ],
                staticClass: "messages-tips"
              },
              [_vm._v(_vm._s(_vm.errors.first("password")))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "messages-tips" }, [
              _vm._v(_vm._s(_vm.password.errortips))
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            class: { "sub-btn": true, disabled: _vm.isloading },
            attrs: { type: "submit", disabled: _vm.isloading }
          },
          [_vm._v(_vm._s(_vm.subbtntext))]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }