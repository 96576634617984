/**
 * Created by Yuanben on 2017/7/19.
 */
import config from '@/config'
export default (params,successCallback, errorCallback)=>{
    // chrome.runtime.sendMessage(
    //     config.extension_id,
    //     params,
    //     {},
    //     function(response) {
    //         if(response.success)
    //         {
    //             if(typeof(successCallback) === 'function')
    //             {
    //                 successCallback();
    //
    //             }
    //             setTimeout(()=>{
    //                 window.close()
    //             },10)
    //         }else{
    //             if(typeof(errorCallback) === 'function')
    //             {
    //                 errorCallback();
    //             }
    //         }
    //     }
    // );
    let port = chrome.runtime.connect(config.extension_id);
    port.postMessage(params);
    port.onMessage.addListener(function(response) {
        if(response.success)
        {
            if(typeof(successCallback) === 'function')
            {
                successCallback();

            }
            setTimeout(()=>{
                window.close()
            },10)
        }else{
            if(typeof(errorCallback) === 'function')
            {
                errorCallback();
            }
        }
    });
}