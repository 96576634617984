var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-overlay",
      attrs: { id: "image-picker", "modal-control": "" }
    },
    [
      _c("div", { staticClass: "modal" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("div", { staticClass: "upload-button" }, [
              _c(
                "form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.type == "cc0",
                      expression: "type=='cc0'"
                    }
                  ],
                  staticClass: "search-form pull-right",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.cc0_search($event)
                    }
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.cc0_query_text,
                        expression: "cc0_query_text"
                      }
                    ],
                    staticClass: "input",
                    attrs: {
                      type: "text",
                      placeholder:
                        "输入搜索关键词，比如相机、猫、电脑、自行车等"
                    },
                    domProps: { value: _vm.cc0_query_text },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.cc0_query_text = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: "submit", attrs: { type: "submit" } },
                    [_vm._v("搜索")]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _vm.loading
              ? _c("div", { staticClass: "loading" }, [
                  _c("img", {
                    attrs: {
                      src: require("../common/loading.gif"),
                      width: "48",
                      height: "48"
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.loading
              ? _c(
                  "div",
                  { staticClass: "image-list clearfix" },
                  _vm._l(_vm.images, function(image) {
                    return _c("div", { staticClass: "image-item" }, [
                      _c(
                        "div",
                        {
                          class: {
                            frame: true,
                            selected: _vm.selectedMap[image.id]
                          },
                          on: {
                            click: function($event) {
                              return _vm.select(image)
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "thumbnail" }, [
                            _vm._m(0, true),
                            _vm._v(" "),
                            _c("div", { staticClass: "hover-mask" }),
                            _vm._v(" "),
                            _c("img", {
                              attrs: {
                                src:
                                  image.web_url +
                                  "?x-oss-process=image/resize,m_fill,w_120,h_120,limit_0"
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "image-title" }, [
                            _vm._v(_vm._s(image.title || "CC0图片"))
                          ])
                        ]
                      )
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.loading && _vm.pageCount > 1
              ? _c("pager", {
                  attrs: { total: _vm.pageCount, page: _vm.page },
                  on: { loadpage: _vm.load }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }, [
          _c("div", { staticClass: "selected-count" }, [
            _vm._v("已选择" + _vm._s(_vm.selected.length) + "张")
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn-yes btn btn-md btn-primary pull-right",
              attrs: { type: "button", disabled: !_vm.selected.length },
              on: {
                click: function($event) {
                  return _vm.finish()
                }
              }
            },
            [_vm._v("确认\n            ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn-no btn btn-md btn-default pull-right",
              attrs: { type: "button" },
              on: { click: _vm.cancel }
            },
            [_vm._v("取消")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "selected-mask" }, [
      _c("div", { staticClass: "icon" }, [
        _c("i", { staticClass: "ybicon yb-succeed" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }