<template>
    <div class="verify">
        <div class="summary">
            已将验证信息发送到您的注册邮箱<br>
            {{email}}请前往此邮箱完成验证
        </div>
        <div class="form">
            <button @click="checkVerify" :disabled="isloading" :class="{'disabled':isloading}" class="sub-btn verify-btn">{{subbtntext}}</button>
            <div v-show="!isValid" class="un-verify-tip">您尚未完成注册，请前往邮箱点击验证通过！</div>
        </div>
        <div class="tip">
            <div >没有收到邮件？</div>
            <div>
                <ul>
                    <li>请检查您邮箱的订阅邮件和垃圾邮件分组</li>
                    <li>如果仍未收到，请尝试<a href="javascript:void(0)" @click="resend()">重新发送确认邮件</a></li>
                </ul>
            </div>
            <div v-show="isResend===1" class="un-verify-tip">邮件发送成功</div>
            <div v-show="isResend===2" class="un-verify-tip">邮件发送失败，请在1分钟后再次尝试</div>
        </div>

    </div>
</template>

<script type="text/ecmascript-6">
    export default {
        name: '',
        data () {
            return {
                isValid:true,
                isResend:0,
                isloading:false,
                subbtntext:'完成验证'
            }
        },
        created(){
            this.email=this.$route.query.email
        },
        methods: {
            checkVerify(){
                this.isloading=true
                this.subbtntext='验证中'
                this.$http.get('/user',{withCredentials: true})
                    .then( (res) => {
                        this.isloading=false
                        this.subbtntext='完成验证'
                        if(res.data.email_verified===1){
                            this.isValid=true
                            this.$router.push({name:'certification',query:{pseudonym:res.data.pseudonym,avatar:res.data.avatar}})
                        }else{
                            this.isValid=false
                        }

                    })

            },
            resend() {
                this.$http.post('/email/confirmation/resend', {withCredentials: true})
                    .then((res) => {
                        this.isResend = 1
                    }, (res) => {
                        this.isResend = 2
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
.verify{
    height: 100%;
    background: url('../assets/left-img.png') left center no-repeat;
    padding-left: 350px;
    padding-top: 70px;
    box-sizing: border-box;
    .summary{
        text-align: center;
        line-height: 1.5;
        margin: 50px auto 30px;
    }
    .tip {
        text-align: center;
        line-height: 1.5;
        margin: 10px auto 30px;
    }
    .un-verify-tip {
        font-size: 12px;
        color: red;
        padding-top: 5px;
    }
    .verify-btn{
        background: none;
        color: #32d182;
        &.disabled{
            color: #fff;
        }
    }
    .form{
        margin: 0 auto;
    }
}
a:link { text-decoration: underline;color: blue}
</style>
