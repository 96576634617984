<template>
    <div class="certification">
        <div class="certification-user">
            <img :src="user.avatar" class="certification-user-img" alt="">
            <div class="certification-user-name">{{user.pseudonym}}</div>
            <div class="loginout" @click="loginout">切换账号</div>
        </div>
        <div class="certification-form form">
            <div class="certification-form-title">完成实名认证成为原创者</div>
            <div class="certification-form-sup"><span>实名认证</span></div>
            <form @submit.prevent="onSubmit" class="form">
                <div class="form-group">
                    <input v-validate="'required'" class="input-role" v-model="model.realname" name="realname" type="text"  placeholder="您的真实姓名"/>
                    <div v-show="errors.has('realname')" class="messages-tips">{{ errors.first('realname') }}</div>
                    <div class="messages-tips">{{realname.errortips}}</div>
                </div>
                <div class="form-group">
                    <input v-validate="{rules:{required:true,regex:/(^\d{15}$)|(^\d{17}([0-9]|X)$)/}}"
                           class="input-role" v-model="model.id_card" name="id_card" type="text"  placeholder="您的身份证号码"/>
                    <div v-show="errors.has('id_card')" class="messages-tips">{{ errors.first('id_card') }}</div>
                    <div class="messages-tips">{{id_card.errortips}}</div>
                </div>
                <div class="form-group">
                    <input v-validate="'required'" autocomplete="off" class="input-role captcha-input" v-model="model.captcha" name="captcha" type="text"  placeholder="验证码"/>
                    <img :src="captchaSrc" @click="changeCaptcha" class="captcha-img">
                    <div v-show="errors.has('captcha')" class="messages-tips">{{ errors.first('captcha') }}</div>
                    <div class="messages-tips">{{captcha.errortips}}</div>
                </div>
                <button type="submit" :disabled="isloading" :class="{'sub-btn':true,'disabled':isloading}">{{subbtntext}}</button>
            </form>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
    import config from '@/config'
    import notify from '@/notify'
    export default {
        name: '',
        data () {
            return {
                user:{
                    avatar:this.$route.query.avatar,
                    pseudonym:this.$route.query.pseudonym
                },
                model: {
                    realname: '',
                    id_card: '',
                    captcha:''
                },
                captchaSrc:config.base_url+'/captcha',
                subbtntext:'确定',
                isloading:false,
                realname:{
                    errortips:''
                },
                id_card:{
                    errortips:''
                },
                captcha:{
                    errortips:''
                }
            }
        },
        methods: {
            loginout(){
                this.$http.post('/user/logout',{},{withCredentials: true})
                    .then( (res) => {
                        this.$router.push({name:'login'})
                    })

            },
            changeCaptcha(){
                this.captchaSrc=config.base_url+'/captcha'+'?s=' + Math.random();
            },
            showErrTip(errData){
                for(let key in errData){
                    this[key].errortips=errData[key].join()
                }
            },
            onSubmit () {
                this.$validator.validateAll().then(res=>{
                    if(!res)return;
                    let data={
                        name:this.model.realname,
                        id_card:this.model.id_card,
                        captcha:this.model.captcha
                    }
                    this.isloading=true
                    this.subbtntext='提交中';
                    this.$http.post('/user/realname',data,{withCredentials: true})
                        .then( (res) => {
                            this.isloading=false
                            this.subbtntext='确定';
                            if(res.response.status===422){
                                this.showErrTip(res.response.data)
                                this.changeCaptcha()
                                return
                            }
                            if(this.$route.query.cc0 == 1){
                                //当用户点击cc0按钮
                              this.$manager.openImagePicker()
                            }else if(this.$route.query.cc0 == 0){
                              this.$manager.login()
                                // notify({method:'loginSuccess'},function () {
                                //     console.log('notify login sucess ...')
                                // })
                            }
                            window.close()

                        })
                        .catch( (err) => {
                            this.isloading=false
                            this.subbtntext='确定';
                            this.showErrTip(err.response.data)
                            this.changeCaptcha()
                        })
                })
            }
        },
        created(){
            this.changeCaptcha()
        }
    }
</script>

<style scoped>
    .certification{
        height: 100%;
        background: url('../assets/left-bg.png') left center no-repeat;
        padding-left: 410px;
        padding-top: 45px;
        box-sizing: border-box;
        position: relative;
        background-size:350px 100% ;
    }
    .certification-user{
        position: absolute;
        width: 350px;
        left: 0;
        top:50%;
        transform: translate3d(0,-50%,0);
        text-align: center;
    }
    .certification-user-img{
        width: 56px;
        height: 56px;
        border-radius:50% ;
        background: #fff;
        margin-bottom: 10px;
    }
    .certification-user-name{
        font-size: 16px;
        color: #fff;
    }
    .loginout {
        font-size: 12px;
        margin-top: 10px;
        color: #fff;
        text-decoration: underline;
        cursor: pointer;
    }
    .certification-form-title{
        font-size: 18px;
        color: #00816a;
        text-align: center;
        margin-bottom: 20px;
    }
    .certification-form-sup{
        position: relative;
        color: #999;
        font-size: 14px;
        text-align: center;
        height: 50px;
        line-height: 50px;
    }
    .certification-form-sup span{
        display: inline-block;
        background: #fff;
        position: relative;
        z-index: 2;
        padding: 0 10px;
    }
    .certification-form-sup:before{
        position: absolute;
        z-index: 1;
        content: '';
        font-size: 0;
        height: 1px;
        width: 100%;
        left: 0;
        top:50%;
        background: #dadedd;
    }
    .captcha-input{
        width: 50%;
        display: inline-block;
    }
    .captcha-img{
        width:40%;
        cursor: pointer;
        vertical-align: bottom;
        float: right;
    }
</style>
