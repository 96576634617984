<template>
    <div class="face">
        <nav-header></nav-header>
        <router-view></router-view>
    </div>
</template>
<script type="text/ecmascript-6">
    import navHeader from '@/pages/nav-header.vue'
    export default {
        name: '',
        data () {
            return {}
        },
        components:{navHeader},
        methods: {}
    }
</script>

<style scoped>
.face{
    height: 100%;
    background: url('../assets/left-img.png') left center no-repeat;
    padding-left: 410px;
    padding-top: 70px;
    box-sizing: border-box;
}
</style>
